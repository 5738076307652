@font-face {
	font-family: 'Avenir';
	src: url('../font/Avenir-Black.woff2') format('woff2'),
	url('./font/Avenir-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-off-black: #1f1f24;
$color-brown: #b77e51;
$color-gray: #858282;

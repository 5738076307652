@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";

html {
	background: $color-white;
	font-family: 'Avenir';
	font-size: 16px;
	font-weight: 900;
	line-height: 1;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-off-black;
}

a {
	color: $color-off-black;

	@include hover-focus {
		color: $color-off-black;
	}
}

.btn {
	display: inline-block;
	letter-spacing: letter-spacing(200);
	padding: rem(20) rem(30);
	text-align: center;
	text-indent: text-indent(200);
	vertical-align: middle;

	&.btn-brown {
		border: 2px solid $color-brown;
		color: $color-off-black;

		@include hover-focus {
			background: $color-brown;
		}
	}

	&.btn-black {
		border: 2px solid $color-off-black;
		color: $color-brown;

		@include hover-focus {
			background: $color-off-black;
		}
	}

	&.btn-full-black {
		border: 2px solid $color-off-black;
		color: $color-off-black;

		@include hover-focus {
			background: $color-off-black;
			color: $color-white;
		}
	}
}

header {
	background: url('../img/bg/bg.png') no-repeat center center;
	background-size: cover;
	padding: rem(20) 0 0;
	.hero{
		display: block;
		img{
			display: block;
			width: 100%;
			height: auto;
			@include tablet-down {
				height: 72vw;
				object-fit: cover;
				object-position: center right;
			}
		}
		@include hover-focus{
			opacity: .8;
		}
	}
	.container {
		position: relative;

		> .content {
			margin: 0 auto rem(20);
			max-width: rem(650);
			position: relative;
			text-align: center;
			z-index: 2;

			@include tablet-down {
				max-width: 100%;
			}

			> .socials {
				list-style: none;
				margin: 0 0 rem(25) 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(20);
					vertical-align: middle;

					+ li {
						margin-left: rem(15);
					}

					> a {
						@include hover-focus {
							color: $color-white;
						}
					}
				}
			}

			> nav {
				> ul {
					list-style: none;
					margin: 0;
					padding: 0;

					> li {
						display: inline-block;
						font-size: rem(25);
						vertical-align: middle;

						+ li {
							margin-left: rem(25);
						}

						> a {
							color: $color-off-black;

							@include hover-focus {
								color: $color-white;
							}
						}
					}
				}
			}

			> h1 {
				margin: rem(50) 0 rem(100) 0;

				> img {
					margin: 0 auto;
				}
			}

			> .releases {
				> div {
					> h2 {
						color: $color-white;
						font-size: rem(25);
						letter-spacing: letter-spacing(50);
						margin: 0 0 rem(20) 0;
						text-indent: text-indent(50);
					}

					+ div {
						margin-top: rem(30);
					}
				}
			}
		}
	}
}

main {
	#music {
		background: url('../img/bg/deco.png') no-repeat left top 20px;
		background-size: 95% 100%;
		padding: rem(150) 0 rem(115) 0;

		@include tablet-down {
			background: none;
			padding: rem(80) 0;
		}

		article {
			align-items: center;
			display: flex;
			flex-direction: row-reverse;
			flex-wrap: nowrap;
			text-align: center;

			@include tablet-down {
				display: block;
			}

			&:nth-child(even) {
				flex-direction: row;
			}

			+ article {
				margin-top: rem(80);
			}

			> div {
				flex: 0 0 50%;

				> img {
					margin: 0 auto;
					width: 100%;

					@include tablet-down {
						margin-bottom: rem(30);
					}
				}

				> h2 {
					font-size: rem(25);
					letter-spacing: letter-spacing(50);
					margin: 0 0 rem(30) 0;
					text-indent: text-indent(50);
				}

				> .links {
					list-style: none;
					margin: 0 auto;
					max-width: rem(300);
					padding: 0;

					+ h2 {
						margin-top: rem(40);
					}

					> li {
						+ li {
							margin-top: rem(40);
						}

						> a {
							display: block;
						}
					}
				}
			}

			&:first-child {
				> div {
					&:first-child {
						flex: 0 0 55%;
					}

					&:last-child {
						flex: 0 0 45%;
					}
				}
			}
		}
	}

	#videos {
		background: url('../img/bg/bg.png') no-repeat center center;
		background-size: cover;
		padding: rem(130) 0 rem(80) 0;

		.owl-carousel {
			padding: 0 rem(80);

			.owl-video-wrapper {
				overflow: hidden;
				padding-bottom: 56.25%;
				position: relative;

				iframe,
				.owl-video-tn {
					background-size: cover;
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
				}
			}

			.video-title {
				display: block;
				font-size: rem(20);
				letter-spacing: letter-spacing(200);
				margin-top: rem(30);
				text-align: center;
				text-indent: text-indent(200);
			}

			.owl-nav {
				> button {
					color: $color-white;
					font-size: rem(40);
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					@include hover-focus {
						color: $color-off-black;
					}

					&.owl-prev {
						left: 0;
					}

					&.owl-next {
						right: 0;
					}
				}
			}
		}
	}

	#tour {
		padding: rem(130) 0;
		text-align: center;

		@include tablet-down {
			padding: rem(80);
		}

		h2 {
			color: $color-off-black;
			font-size: rem(50);
			font-weight: normal;
			letter-spacing: letter-spacing(50);
			margin: 0 0 rem(90) 0;
			text-indent: text-indent(50);

			@include tablet-down {
				margin-bottom: rem(45);
			}
		}

		#events {
			> div {
				align-items: center;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				margin: 0 rem(-10);

				@include tablet-down {
					display: block;
					margin: 0;
				}

				> div {
					font-size: rem(18);
					padding: 0 rem(10);
					text-align: left;
					text-transform: none;

					@include tablet-down {
						text-align: center;

						+ div {
							margin-top: rem(10);
						}
					}

					&.event-date {
						flex: 0 0 15%;
						text-transform: uppercase;
					}

					&.event-location {
						flex: 0 0 32.5%;
					}

					&.event-venue {
						flex: 0 0 32.5%;
					}

					&.event-links {
						flex: 0 0 20%;
						text-align: right;

						@include tablet-down {
							text-align: center;
						}

						> .btn {
							padding: rem(15) rem(20);
							text-transform: uppercase;
						}
					}
				}

				+ div {
					margin-top: rem(25);
				}
			}
		}
	}
}

footer {
	padding: rem(65) 0;
	text-align: center;

	.copyright {
		color: $color-gray;
		font-size: rem(12);
		letter-spacing: letter-spacing(400);
		line-height: line-height(22, 12);
		text-indent: text-indent(400);

		p {
			margin: 0;

			a {
				color: $color-gray;
				@include hover-focus {
					color: $color-gray;
					text-decoration: underline;
				}
			}
		}
	}
}
